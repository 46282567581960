import { StatusBar } from 'expo-status-bar';
import { Pressable, StyleSheet, Text, View, SafeAreaView } from 'react-native';

export default function App() {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.logoText}>MARCONNABIS</Text>
      </View>

      <View style={styles.heroContainer}>
        <Text style={styles.heroText}>Exceptional quality within your reach.</Text>
      </View>

      <View style={styles.descriptionContainer}>
        <Text style={styles.descriptionText}>Rooted in the essence of nature.</Text>
        <Text style={styles.descriptionText}>Our meticulous attention to every detail, from origin to delivery, ensures a distinctive and pure CBD experience.</Text>
      </View>

      <View style={styles.productsContainer}>
        <Pressable style={styles.productButton}>
          <Text style={styles.productButtonText}>Coming Soon</Text>
        </Pressable>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  headerContainer: {
    backgroundColor: 'black',
    paddingHorizontal: 24,
    paddingVertical: 38,
  },
  logoText: {
    color: 'white',
    fontSize: 33
  },
  heroContainer: {
    backgroundColor: 'black',
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  heroText: {
    color: 'white',
    fontSize: 34,
    fontweight: 'bold',
    width: 190,
  },
  descriptionContainer: {
    backgroundColor: 'black',
    paddingHorizontal: 24,
    alignItems: 'center',
    paddingVertical: 38,
  },
  descriptionText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
    width: 270,
  },
  productsContainer: {
    backgroundColor: 'black',
    paddingHorizontal: 24,
    paddingVertical: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  productButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 2,
    width: 326,
    paddingVertical: 24,
    borderWidth: 1,
    borderColor: '#fff',
  },
  productButtonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
});
